import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { Product } from "src/app/dp-products/product.model";
import { OrderService } from "src/app/dp-services/order.service";
import { ProductService } from "src/app/dp-services/product.service";
import { CheckoutService } from "../cart-service/checkout.service";
import { Country } from "../cart-service/countries.model";
import { PaymentService } from "../cart-service/payment.service";
import Decimal from "decimal.js";
import { map } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-product-checkout",
  templateUrl: "./product-checkout.component.html",
  styleUrls: ["./product-checkout.component.scss"],
})
export class ProductCheckoutComponent implements OnInit {
  paymentHandler: any = null;
  repeatTrue: boolean;
  repeatValue: string;
  openStripe: boolean;
  invalidIVA = false;
  errorText = "";
  couponID: null | number = null;
  couponAmount: number = 0;
  stripekey: string = "";

  public checkoutForm: FormGroup;

  public prodotti$: Observable<Product[]>;

  public payment: string = "Stripe";
  public amount: any;
  public countries: Country[];
  public richiestaFattura: boolean = false;
  fatturaValida: boolean;

  garanzia4 = 0;
  newCustomerCheckout: any;
  newOrder: any;

  spedizioneScelta = 0;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    public productService: ProductService,
    private orderService: OrderService,
    private paymentService: PaymentService,
    private coService: CheckoutService
  ) {
    this.checkoutForm = this.fb.group({
      firstname: ["", [Validators.required, Validators.maxLength(50)]],
      lastname: ["", [Validators.required, Validators.maxLength(50)]],
      phone: [
        "",
        [
          Validators.required,
          Validators.pattern("[0-9]+"),
          Validators.maxLength(30),
        ],
      ],
      email: [
        "",
        [Validators.required, Validators.email, Validators.maxLength(50)],
      ],
      address: ["", [Validators.required, Validators.maxLength(70)]],
      country: ["", [Validators.required]],
      town: ["", [Validators.required, Validators.maxLength(30)]],
      state: ["", [Validators.required, Validators.maxLength(30)]],
      postalcode: ["", [Validators.required, Validators.maxLength(10)]],
      companyname: ["", [Validators.maxLength(70)]],
      vat: ["", [Validators.pattern("[0-9]+"), Validators.maxLength(30)]],
      vatcountry: ["", [Validators.maxLength(30)]],
      sdicode: ["", [Validators.maxLength(30)]],
      couponcode: ["", [Validators.maxLength(30)]],
      couponamount: [0, [Validators.nullValidator]],
      richiestaFattura: [false],
    });
  }

  userCode: string;
  userData: any;

  ngOnInit(): void {
    this.repeatValue = "";
    this.coService
      .getCountries()
      .toPromise()
      .then((data) => {
        this.countries = data;
      });

    this.coService
      .getStripeToken()
      .toPromise()
      .then((response) => {
        if (response.key !== undefined) {
          this.stripekey = response.key;
        }
      });

    this.userCode = localStorage.getItem("css-user-code");
    this.orderService.isExistUserCode(this.userCode).subscribe((response) => {
      if (
        response !== "Nessun utente trovato" &&
        response !== undefined &&
        response.ID != 0
      ) {
        this.userData = response;
        this.checkoutForm.get("lastname").setValue(this.userData.LastName);
        this.checkoutForm.get("firstname").setValue(this.userData.FirstName);
        this.checkoutForm.get("address").setValue(this.userData.Address);
        this.checkoutForm.get("town").setValue(this.userData.Town);
        this.checkoutForm.get("state").setValue(this.userData.StateProvince);
        this.checkoutForm.get("email").setValue(this.userData.Email);
        this.checkoutForm.get("phone").setValue(this.userData.Phone);
        this.checkoutForm.get("postalcode").setValue(this.userData.PostalCode);
        //this.checkoutForm.get('companyname').setValue(this.userData.CompanyName);
        //this.checkoutForm.get('sdicode').setValue(this.userData.SDIcode);
        this.checkoutForm.get("couponcode").setValue("");
        this.checkoutForm.get("vat").setValue("");
        this.checkoutForm.get("vatcountry").setValue("");
        this.checkoutForm.get("richiestaFattura").setValue(false);
        if (
          this.userData.Country !== "" &&
          this.userData.Country !== null &&
          this.userData.Country !== undefined
        ) {
          this.setProducts();
          this.calcolaSpedizione();
        }
      }
    });
    this.controlTotalOrder();
  }

  controlTotalOrder() {
    this.orderService
      .getTotalOrderByCustomerCode(this.userCode)
      .toPromise()
      .then((res) => {
        if (res !== this.amount) {
          this.productService.getProducts.subscribe((data) => {
            if (data !== undefined) {
              localStorage["products"] = JSON.stringify(data);
              let carrello = this.productService
                .gethttpProductsInCart()
                .pipe(map((data) => data));
              carrello.subscribe((next) => {
                if (next !== "null" && next !== null && next !== undefined) {
                  localStorage["cartItems"] = JSON.stringify(next);
                  this.setProducts();
                  this.getTotal.toPromise().then((amount) => {
                    this.amount = amount;
                    this.calcolaSpedizione();
                  });
                }
              });
            }
          });
        } else {
          this.setProducts();
        }
      });
  }

  setProducts() {
    this.prodotti$ = this.productService.cartItems;
  }

  public get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount(
      this.spedizioneScelta,
      this.couponAmount
    );
  }

  updatePage() {
    window.location.reload();
  }

  public closeResult: string;
  checkout(typeofpayment: string, content: any) {
    this.orderService
      .getTotalOrderByCustomerCode(this.userCode)
      .toPromise()
      .then((res) => {
        if (res !== this.amount) {
          this.modalService.open(content, {
            size: "lg",
            centered: true,
          });
        } else {
          this.newCustomerCheckout = {
            LastName: this.checkoutForm.get("lastname")?.value,
            FirstName: this.checkoutForm.get("firstname")?.value,
            Address: this.checkoutForm.get("address")?.value,
            Town: this.checkoutForm.get("town")?.value,
            StateProvince: this.checkoutForm.get("state")?.value,
            Country: this.checkoutForm.get("country")?.value,
            Email: this.checkoutForm.get("email")?.value,
            Phone: Number(this.checkoutForm.get("phone")?.value),
            PostalCode: this.checkoutForm.get("postalcode")?.value,
            CustomerCode: this.userCode,
            CouponCode: this.checkoutForm.get("couponcode").value,
            CouponAmount: this.checkoutForm.get("couponamount").value,
          };
          this.prodotti$.subscribe((res) => {
            if (res) {
              if (this.userData !== undefined) {
                if (
                  this.userData.FirstName ===
                    this.newCustomerCheckout.FirstName &&
                  this.userData.LastName === this.newCustomerCheckout.LastName
                ) {
                  this.orderService
                    .updateUserData(this.newCustomerCheckout, this.userData.ID)
                    .subscribe((updateUser) => {
                      this.createOrder(
                        this.userData.ID,
                        this.newCustomerCheckout.Country,
                        this.amount +
                          this.garanzia4 +
                          this.spedizioneScelta -
                          this.couponAmount,
                        typeofpayment,
                        res
                      );
                    });
                } else {
                  this.orderService.createUserCode().subscribe((newcode) => {
                    var vecchioCodice = localStorage.getItem("css-user-code");
                    var nuovoCodice = newcode;
                    this.orderService
                      .updateUserCode(nuovoCodice, vecchioCodice)
                      .toPromise()
                      .then((codice) => {
                        localStorage.setItem("css-user-code", newcode);
                        this.newCustomerCheckout.CustomerCode = newcode;
                        this.orderService
                          .saveUserData(this.newCustomerCheckout)
                          .subscribe((responseUserID) => {
                            this.createOrder(
                              responseUserID,
                              this.newCustomerCheckout.Country,
                              this.amount +
                                this.garanzia4 +
                                this.spedizioneScelta -
                                this.couponAmount,
                              typeofpayment,
                              res
                            );
                          });
                      });
                  });
                }
              } else {
                this.newCustomerCheckout.CustomerCode = this.userCode;
                this.orderService
                  .saveUserData(this.newCustomerCheckout)
                  .subscribe((responseUserID) => {
                    this.createOrder(
                      responseUserID,
                      this.newCustomerCheckout.Country,
                      this.amount +
                        this.garanzia4 +
                        this.spedizioneScelta -
                        this.couponAmount,
                      typeofpayment,
                      res
                    );
                  });
              }
            }
          });
        }
      });
  }

  createOrder(
    userid: any,
    country: string,
    orderTotal: any,
    typeofpayment: string,
    prodotti: any[]
  ) {
    this.newOrder = {
      CustomerID: Number(userid),
      CountryOfOrigin: country,
      OrderTotal: Number(orderTotal.toFixed(2)),
      DeliveryPrice: Number(this.spedizioneScelta.toFixed(2)),
      CouponID: this.couponID,
      CouponAmount: this.couponAmount,
      CompanyName: this.checkoutForm.get("companyname")?.value,
      VAT:
        this.checkoutForm.get("vatcountry")?.value +
        this.checkoutForm.get("vat")?.value,
      SDIcode: this.checkoutForm.get("sdicode").value,
      Fattura: this.checkoutForm.get("richiestaFattura").value,
    };
    this.orderService.saveOrder(this.newOrder).subscribe((responseOrder) => {
      var idOrdine = responseOrder;
      if (idOrdine !== undefined) {
        this.makePayment(
          Number(orderTotal.toFixed(2)),
          idOrdine,
          prodotti,
          country
        );
      }
    });
  }

  extendWarranty(product) {
    this.prodotti$.subscribe((res) => {
      if (res) {
        res.forEach((el, i) => {
          if (el.ID === product.ID) {
            el.Warranty = !el.Warranty;
            if (el.Warranty === true) {
              this.garanzia4 =
                this.garanzia4 +
                this.calculationWarranty(product) * product.Quantity;
            } else {
              this.garanzia4 =
                this.garanzia4 -
                this.calculationWarranty(product) * product.Quantity;
            }
          }
        });
      }
    });
  }

  calculationWarranty(product) {
    var garanzia = (product.Price / 100) * 3.5;
    return Number(garanzia.toFixed(2));
  }

  insuredShipping() {
    var totalShipping = 0;
    this.prodotti$.subscribe((res) => {
      if (res) {
        totalShipping = res
          .map((a) => (a.Price - a.Discount) * a.Quantity)
          .reduce((a, b) => a + b, 0);
        totalShipping = (totalShipping / 100) * 1.2 + 10.5;
        totalShipping = Number(totalShipping.toFixed(2));
      }
    });
    return totalShipping;
  }

  makePayment(totAmount: any, idOrdine: any, prodotti: any, country: string) {
    //configurazione stripe
    console.log("inizio configurazione stripe");
    console.log("pagamento di: ", totAmount, " euro");

    let totale = new Decimal(totAmount).times(100);

    let payload = {
      amount: totale.toNumber(),
      currency: "EUR",
      email: this.newCustomerCheckout.Email,
      order_id: idOrdine,
      name: this.newCustomerCheckout.FirstName,
      surname: this.newCustomerCheckout.LastName,
      country: this.newCustomerCheckout.Country,
      city: this.newCustomerCheckout.Town,
      postal_code: this.newCustomerCheckout.PostalCode,
      state: this.newCustomerCheckout.StateProvince,
      address: this.newCustomerCheckout.Address,
      sdicode: this.newCustomerCheckout.SDIcode,
    };
    this.paymentService.stripeCallback(payload).subscribe(
      (link: string) => {
        if (link) {
          window.location.href = link;
          // window.open(link, "_blank");
        }
      },
      (err) => {
        window.location.href = err.error.tex;
        //window.open(err.error.text, "_blank");
      }
    );
  }

  doSomethingWhenStripePopUpCloses(idOrdine: any, country: string) {
    var newPayment = {
      OrderID: idOrdine,
      PaymentType: "Stripe",
      Country: country,
      Status: "annullato",
    };
    this.paymentService.addPayment(newPayment).subscribe((responsePayment) => {
      //console.log(responsePayment)
    });
  }

  repeatePswd(value: any, value2: any) {
    if (value === 1) {
      this.repeatTrue = false;
      this.repeatValue = value2.target.value;
      if (this.repeatValue === this.checkoutForm.get("pswd")?.value) {
        this.repeatTrue = true;
      } else {
        this.repeatTrue = false;
      }
    } else {
      if (this.repeatValue !== "") {
        if (this.repeatValue === this.checkoutForm.get("pswd")?.value) {
          this.repeatTrue = true;
        } else {
          this.repeatTrue = false;
        }
      }
    }
  }

  disablePlaceOrder() {
    if (this.checkoutForm.get("richiestaFattura").value == true) {
      this.checkoutForm
        .get("vat")
        .setValidators([
          Validators.required,
          Validators.pattern("[0-9]+"),
          Validators.maxLength(30),
        ]);
      this.checkoutForm
        .get("vatcountry")
        .setValidators([Validators.required, Validators.maxLength(30)]);
      this.checkoutForm
        .get("sdicode")
        .setValidators([Validators.required, Validators.maxLength(30)]);
      this.checkoutForm
        .get("companyname")
        .setValidators([Validators.required, Validators.maxLength(70)]);
    } else {
      this.checkoutForm.get("vat").clearValidators();
      this.checkoutForm.get("vatcountry").clearValidators();
      this.checkoutForm.get("sdicode").clearValidators();
      this.checkoutForm.get("companyname").clearValidators();
    }
    this.checkoutForm.get("vat").updateValueAndValidity();
    this.checkoutForm.get("vatcountry").updateValueAndValidity();
    this.checkoutForm.get("sdicode").updateValueAndValidity();
    this.checkoutForm.get("companyname").updateValueAndValidity();

    //this.fatturaValida !== false && this.fatturaValida !== undefined &&
    if (
      this.checkoutForm.get("richiestaFattura").value == true &&
      this.checkoutForm.valid
    ) {
      return false;
    } else if (
      this.checkoutForm.get("richiestaFattura").value == false &&
      this.checkoutForm.valid
    ) {
      return false;
    }
    return true;
  }

  verificaIVA() {
    const VAT = this.checkoutForm.get("vat")?.value;
    const VATcountry = this.checkoutForm.get("vatcountry")?.value;

    if (VAT !== "" && VATcountry !== "") {
      this.invalidIVA = false;
      this.coService
        .checkIva(VATcountry, VAT)
        .toPromise()
        .then((response) => {
          if (response !== undefined) {
            this.fatturaValida = response.isValid;
            if (this.fatturaValida === true && response.name !== undefined) {
              this.checkoutForm.get("companyname").setValue(response.name);
            }
          }
        });
    } else {
      this.invalidIVA = true;
    }
  }

  calcolaSpedizione() {
    this.prodotti$.subscribe((res) => {
      if (res) {
        var paese = "";
        if (
          this.checkoutForm.get("country")?.value !== undefined &&
          this.checkoutForm.get("country")?.value !== ""
        ) {
          paese = this.checkoutForm.get("country")?.value;
        }
        if (paese !== "Italia" && paese !== "") {
          paese = "Europa";
        }

        var prezzotot = 0;
        res.forEach((product) => {
          if (product.Price) {
            let discountPrice = 0;
            if (product.Discount.OrderMin <= product.Quantity) {
              discountPrice = product.DiscountPrice;
            }
            let prezzoXprodotto =
              (product.Price - discountPrice) * product.Quantity;
            prezzotot += prezzoXprodotto;
          }
        });
        if (paese === "") {
          this.spedizioneScelta = 0;
        } else {
          this.coService
            .costoSpedizione(paese, prezzotot)
            .toPromise()
            .then((response) => {
              if (response !== "") {
                this.spedizioneScelta = response.Prezzo;
              }
            });
        }
      }
    });
  }

  verificaCoupon() {
    this.errorText = "";
    const email = this.checkoutForm.get("email")?.value;
    const codice = this.checkoutForm.get("couponcode")?.value;
    this.orderService
      .checkCoupon(email, codice, this.amount)
      .toPromise()
      .then((response) => {
        if (response.ID !== undefined) {
          this.couponID = response.ID;
          if (response.DiscountTypeID === 1) {
            //sconto fisso
            this.couponAmount = response.Amount;
          } else if (response.DiscountTypeID === 2) {
            //percentuale
            this.couponAmount = Number(
              ((this.amount / 100) * response.Amount).toFixed(2)
            );
          }
          this.checkoutForm.get("couponamount").setValue(this.couponAmount);
        } else {
          this.errorText = response;
        }
      });
  }

  alertClose() {
    this.errorText = "";
  }

  changeEmail() {
    if (this.checkoutForm.get("email").value === "") {
      this.couponAmount = 0;
      this.checkoutForm.get("couponamount").setValue(0);
      this.checkoutForm.get("couponcode")?.setValue("");
      this.errorText = "";
    }
  }
}
