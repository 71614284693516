<!--<app-product-breadcrumb [title]="'menu.visite'" [breadcrumb]="'menu.visite'"></app-product-breadcrumb>-->

<section class="p-0">
    <app-slider-visit [sliders]="sliders" [textClass]="'text-center'" [buttonText]="'visite.btn-reserve' | translate">
    </app-slider-visit>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="gold-title text-center">{{'visite.info1.titolo' | translate}}</h2>
            </div>
            <div class="col-md-8">
                <div>
                    <p class="text" [innerHTML]="'visite.info1.paragrafo1'  | translate"> </p>
                    <p class="text" [innerHTML]="'visite.info1.paragrafo2'  | translate"> </p>
                </div>
            </div>
            <div class="col-md-4 m-0">
                <div class="float-right">
                    <img src="assets/soragna/caseificio_foto00.jpeg" style="width: 100%;" alt="Caseificio Soragna">
                </div>
            </div>
            <div class="col-md-12 mt-3" *ngIf="caseificiAperti">
                <div style="border: #B8AB69 solid 2px;">
                    <p class="text m-4" [innerHTML]="'visite.info1.paragrafo3'  | translate"> </p>
                </div>
            </div>
            <div class="col-md-12 mt-3 text-center">
                <a routerLink="/visits" fragment="book">
                    <button class="btn btn-solid" type="submit">{{'visite.btn-reserve' | translate}}</button>
                </a>
            </div>
            <div class="col-md-12 mt-3">
                <div class="ornament"></div>
            </div>
            <!--<div class="col-md-12 mt-3">
                <app-services></app-services>
            </div>-->
            <div class="col-md-12 pt-4">
                <div>
                    <p class="text" [innerHTML]="'visite.info2'  | translate">
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="ornament"></div>

<section class="contact-page section-b-space" id="book">
    <div class="container">
        <div class="row section-b-space border-contact">
            <div class="col-md-12 pt-4">
                <div>
                    <p class="text" [innerHTML]="'visite.info3'  | translate">
                    </p>
                </div>
            </div>
            <div class="col-lg-12">
                <form (ngSubmit)="reserveNow()" [formGroup]="visitForm">
                    <div class="form-row">
                        <div class="col-md-6 mt-3">
                            <label for="name">{{'visite.form.Nome' | translate}}</label>
                            <input type="text" class="form-control" formControlName="FirstName"
                                placeholder="{{'visite.form.Nome' | translate}}"
                                [ngClass]="{'is-invalid': submitted && form.FirstName.errors}" />
                            <div *ngIf="submitted && form.FirstName.errors" class="invalid-feedback">
                                <span *ngIf="form.FirstName.errors.required">{{ 'visite.form.Obbligatorio' |
                                    translate}}</span>
                                <span *ngIf="form.FirstName.errors.maxlength">{{'visite.form.Max' | translate }} 50
                                    {{'visite.form.Caratteri' | translate}}</span>
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label for="last-name">{{'visite.form.Cognome' | translate}}</label>
                            <input type="text" class="form-control" formControlName="LastName"
                                placeholder="{{'visite.form.Cognome' | translate}}"
                                [ngClass]="{'is-invalid': submitted && form.LastName.errors}" />
                            <div *ngIf="submitted && form.LastName.errors" class="invalid-feedback">
                                <span *ngIf="form.LastName.errors.required">{{ 'visite.form.Obbligatorio' | translate
                                    }}</span>
                                <span *ngIf="form.LastName.errors.maxlength">{{'visite.form.Max' | translate }} 50
                                    {{'visite.form.Caratteri' | translate}}</span>
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label for="phone">{{'visite.form.Telefono' | translate}}</label>
                            <input type="number" class="form-control" formControlName="Phone"
                                placeholder="{{'visite.form.Telefono' | translate}}"
                                [ngClass]="{'is-invalid': submitted && form.Phone.errors}" />
                            <div *ngIf="submitted && form.Phone.errors" class="invalid-feedback">
                                <span *ngIf="form.Phone.errors.required">{{ 'visite.form.Obbligatorio' | translate
                                    }}</span>
                                <span *ngIf="form.Phone.errors.maxlength">{{'visite.form.Max' | translate }} 15
                                    {{'visite.form.Caratteri' | translate}} </span>
                                <span *ngIf="form.Phone.errors.pattern">{{ 'visite.form.Numeri' | translate }}</span>
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label for="email">{{'visite.form.Email' | translate}}</label>
                            <input type="text" class="form-control" formControlName="Email"
                                placeholder="{{'visite.form.Email' | translate}}"
                                [ngClass]="{'is-invalid': submitted && form.Email.errors}" />
                            <div *ngIf="submitted && form.Email.errors" class="invalid-feedback">
                                <span *ngIf="form.Email.errors.required">{{ 'visite.form.Obbligatorio' | translate
                                    }}</span>
                                <span *ngIf="form.Email.errors.email">{{ 'visite.form.EmailNonValida' | translate
                                    }}</span>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3" style="display: none;">
                            <label for="Paese">{{'visite.form.Paese' | translate}}</label>
                            <input type="text" class="form-control" formControlName="Paese"
                                placeholder="{{'visite.form.Paese' | translate}}" />
                        </div>
                        <div class="col-md-6 mt-3">
                            <label for="person">N° {{'visite.form.Persone'| translate }}</label>
                            <input type="number" class="form-control" formControlName="Nperson" maxlength="2"
                                placeholder="{{ 'visite.form.NPersone' | translate }}" (change)="calcolaTotale()"
                                [ngClass]="{'is-invalid': submitted && form.Nperson.errors}" />
                            <div *ngIf="submitted && form.Nperson.errors" class="invalid-feedback">
                                <span *ngIf="form.Nperson.errors.required">{{ 'visite.form.Obbligatorio' | translate
                                    }}</span>
                                <span *ngIf="form.Nperson.errors.maxlength">{{'visite.form.Max' | translate }} 2
                                    {{'visite.form.Caratteri' | translate}}</span>
                                <span *ngIf="form.Nperson.errors.pattern">{{ 'visite.form.Numeri' | translate }}</span>
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label for="child">N° {{ 'visite.form.Bambini' | translate }}</label>
                            <input type="number" class="form-control" formControlName="Nchild" maxlength="2"
                                placeholder="{{'visite.form.NBambini' | translate }}" (change)="calcolaTotale()"
                                [ngClass]="{'is-invalid': submitted && form.Nchild.errors}" />
                            <div *ngIf="submitted && form.Nchild.errors" class="invalid-feedback">
                                <span *ngIf="form.Nchild.errors.required">{{ 'visite.form.Obbligatorio' | translate
                                    }}</span>
                                <span *ngIf="form.Nchild.errors.maxlength">{{'visite.form.Max' | translate }} 2
                                    {{'visite.form.Caratteri' | translate}}</span>
                                <span *ngIf="form.Nchild.errors.pattern">{{ 'visite.form.Numeri' | translate }}</span>
                            </div>
                        </div>

                        <div class="col-md-6 mt-3">
                            <label for="date">{{ 'visite.form.Data' | translate }}*</label>
                            <input type="date" class="form-control" formControlName="VisitDate"
                                placeholder="{{ 'visite.form.Data'  | translate }}" (change)="changeVisitDate()"
                                min="{{ tomorrow | date: 'yyyy-MM-dd'}}"
                                [ngClass]="{'is-invalid': submitted && form.VisitDate.errors}" />
                            <div *ngIf="submitted && form.VisitDate.errors" class="invalid-feedback">
                                <span *ngIf="form.VisitDate.errors.required">{{ 'visite.form.Obbligatorio' | translate
                                    }}</span>
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label for="language">{{ 'visite.form.Lingua'| translate }}*</label>
                            <select name="language" id="language" class="form-control" formControlName="Language"
                                (change)="changeVisitDate()">
                                <!-- class="form-select"-->
                                <option value="italiano" selected>Italiano</option>
                                <option value="english">Inglese</option>
                            </select>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label for="VisitTime">{{'visite.form.Orario' | translate }}*</label>
                            <select name="VisitTime" id="VisitTime" class="form-control" formControlName="VisitTime"
                                [ngClass]="{'is-invalid': submitted && (form.VisitTime.errors || visitForm.get('VisitTime')?.value === '') }"
                                (change)="changeVisitTime($event); calcolaTotale()">
                                <!-- class="form-select"-->
                                <option *ngFor="let item of timeOptions" [value]="item.id">{{item.ora}}</option>
                            </select>

                            <div *ngIf="submitted && (form.VisitTime.errors || visitForm.get('VisitTime')?.value == '')"
                                class="invalid-feedback">
                                <span>{{ 'visite.form.Obbligatorio' | translate
                                    }}</span>
                            </div>
                        </div>
                        <div class="col-md-3 mt-3">
                            <div class="form-check mt-5">
                                <input class="form-check-input" type="checkbox" formControlName="Tasting"
                                    (change)="visitForm.get('Tasting2')?.setValue(false);calcolaTotale()" />
                                <label class="control-label form-check-label">
                                    {{ 'visite.form.Degustazione'| translate }}
                                </label>
                            </div>
                        </div>
                        <div *ngIf="isDegustazioneAnoliniAvailable"  class="col-md-3 mt-3">
                            <div class="form-check mt-5">
                                <input class="form-check-input" type="checkbox" formControlName="Tasting2"
                                    (change)="visitForm.get('Tasting')?.setValue(false);calcolaTotale()" />
                                <label class="control-label form-check-label">
                                    {{ 'visite.form.DegustazioneAnolini'| translate }}
                                </label>
                            </div>
                        </div>

                        <div class="col-md-12 mt-3 d-flex justify-content-center">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="AcceptPrivacy"
                                    [ngClass]="{'is-invalid': submitted && form.AcceptPrivacy.errors}" />
                                <label class="control-label form-check-label">
                                    <div (click)="openNewTab()">
                                        <a class="accept-privacy">
                                            {{ 'visite.form.AcceptPrivacy' | translate }}
                                        </a>
                                    </div>
                                </label>
                                <div *ngIf="submitted && form.AcceptPrivacy.errors" class="invalid-feedback">
                                    <span *ngIf="form.AcceptPrivacy.errors.required">{{ 'visite.form.Obbligatorio' |
                                        translate }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3 text-center">
                            Totale da pagare: {{totaleVisita}} euro
                        </div>
                        <div class="col-md-12 mt-3 text-center">
                            <button class="btn btn-solid" type="submit">{{
                                'visite.form.Invia' | translate }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

<div class="ornament"></div><br>

<app-email-confirm #emailSent></app-email-confirm>
<app-tap-to-top></app-tap-to-top>