import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SliderService } from '../dp-home/home-service/slider.service';
import { EmailConfirmComponent } from '../dp-modal/email-confirm/email-confirm.component';
import { VisitService } from '../dp-services/visit.service';
import { VisitPrice } from './VisitPrice.model';

@Component({
  selector: 'app-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.scss']
})
export class VisitComponent implements OnInit {

  @ViewChild("emailSent") emailSent: EmailConfirmComponent;
  totaleVisita = 0;
  visitForm!: FormGroup;
  submitted = false;

  timeOptions: { id: string, ora: string }[] = [];
  orarioVisita: any;
  tomorrow: any;

  caseificiAperti: boolean = false;

  isDegustazioneAvailable = false;
  isDegustazioneAnoliniAvailable = false;

  public sliders = [];

  //aggiungo nuova variabile con il relativo model per gestione prezzi dinamici
  prezzi: VisitPrice;
  visitId: number;

  constructor(
    private formBuilder: FormBuilder,
    private slidersService: SliderService,
    private httpVisit: VisitService,
    private cdr: ChangeDetectorRef,
    ) { }

  ngOnInit(): void {
    var date = new Date();
    this.caseificiAperti = date < new Date('2024-10-06');
    // add a day
    this.tomorrow = date.setDate(date.getDate() + 1);
     
    this.timeOptions = [
      {
        id: "",
        ora: "Prima selezionare il N° persone, la data e la lingua"
      }
    ]
    this.visitForm = this.formBuilder.group({
      FirstName: ['', [Validators.required, Validators.maxLength(50)]],
      LastName: ['', [Validators.required, Validators.maxLength(50)]],
      Phone: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.maxLength(15)]],
      Email: ['', [Validators.required, Validators.email]],
      Nperson: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.maxLength(2)]],
      Nchild: ['', [Validators.pattern('[0-9]+'), Validators.maxLength(2)]],
      Language: ['italiano', [Validators.required]],
      VisitDate: [this.timeOptions[0].id, [Validators.required]],
      VisitTime: ["", [Validators.required]],
      Tasting: [false, [Validators.required]],
      Tasting2: [false, [Validators.required]],
      Paese: ['', [Validators.nullValidator]],
      AcceptPrivacy: [false, [Validators.requiredTrue]]
    });

    this.setSlides();
    this.cdr.detectChanges();
    this.calcolaTotale();
  
  }

  get form() {
    return this.visitForm.controls;
  }



  reserveNow() {
    this.submitted = true;
    if (this.visitForm.valid && this.visitForm.get('VisitTime')?.value && this.visitForm.get('VisitTime')?.value !== "") {
      const FirstName = this.visitForm.get('FirstName')?.value;
      const LastName = this.visitForm.get('LastName')?.value;
      const Phone = this.visitForm.get('Phone')?.value;
      const Email = this.visitForm.get('Email')?.value;
      const Nperson = this.visitForm.get('Nperson')?.value;
      const Nchild = this.visitForm.get('Nchild')?.value;
      const Language = this.visitForm.get('Language')?.value;
      const VisitDate = this.visitForm.get('VisitDate')?.value;
      const Tasting = this.visitForm.get('Tasting')?.value;
      const Tasting2 = this.visitForm.get('Tasting2')?.value; 
      const Paese = this.visitForm.get('Paese')?.value;
    
       const selectedVisitTime = this.timeOptions.find(
        item => item.id === this.visitForm.get('VisitTime')?.value
    );

    const VisitTime = selectedVisitTime ? selectedVisitTime.ora : null;

      var totaleDaPagare = this.calcolaTotaleVisita(Number(Nperson), Number(Nchild), VisitDate, Tasting, Language, Tasting2)

      if (Paese === '' || Paese === null || Paese === undefined) {
        var newVisitData = {
          FirstName: FirstName,
          LastName: LastName,
          Phone: Number(Phone),
          Email: Email,
          Nperson: Number(Nperson),
          Nchild: Number(Nchild),
          Language: Language,
          VisitDate: VisitDate,
          VisitTime: VisitTime,
          Tasting: Tasting,
          Tasting2: Tasting2,
          Total: totaleDaPagare
        }
        console.log(VisitTime)

        this.httpVisit.saveVisitData(newVisitData).subscribe(response => {
          this.submitted = false;
          this.visitForm.reset();
          var idVisita = response;
          if (idVisita !== undefined) {
            this.makePayment(newVisitData, idVisita);
          }
        })
      }
    }
  }

  makePayment(visitData: any, idVisita: any) {
    //configurazione stripe
    console.log("inizio configurazione stripe");
    console.log("pagamento di: ", visitData.Total, " euro");
    let payload = {
      "amount": visitData.Total * 100,
      "currency": "EUR",
      "email": visitData.Email,
      "visit_id": idVisita,
      "name": visitData.FirstName,
      "surname": visitData.LastName,
      "phone": visitData.Phone,
      "n_person": visitData.Nperson,
      "n_child": visitData.Nchild,
      "visit_date": visitData.VisitDate,
      "visit_time": visitData.VisitTime,
      "language": visitData.Language,
      "tasting": visitData.Tasting,
      "tasting2": visitData.Tasting2,
    }
    this.httpVisit.payVisit(payload).subscribe((link: string) => {
      if (link) {
        window.location.href = link;
        // window.open(link, "_blank");
      }
    }, err => {
      window.location.href = err.error.tex;
      //window.open(err.error.text, "_blank");
    })
  }

  calcolaTotale(): Promise<void> {
    return new Promise((resolve, reject) => {
        console.log(this.timeOptions)
        const selectedIndex = this.timeOptions.findIndex(item => item.id === this.visitForm.get('VisitTime')?.value);
        
        if (selectedIndex !== -1) {
            this.httpVisit.getVisitPrices(this.timeOptions[selectedIndex].id).subscribe((data) => {
              console.log("data:", data)
                this.prezzi = data.price;
                this.isDegustazioneAvailable = data.degustazione_semplice;
                this.isDegustazioneAnoliniAvailable = data.degustazione_anolini;
                console.log("degustazione:", this.isDegustazioneAnoliniAvailable)

                const Nperson = this.visitForm.get('Nperson')?.value;
                const Nchild = this.visitForm.get('Nchild')?.value;
                const Tasting = this.visitForm.get('Tasting')?.value;
                const Tasting2 = this.visitForm.get('Tasting2')?.value;
                const VisitLang = this.visitForm.get('Language')?.value;
                const VisitTime = this.visitForm.get('VisitTime')?.value;


                this.totaleVisita = Number(Nperson) * this.prezzi.PrezzoAdulto + Number(Nchild) * this.prezzi.PrezzoBambino;
                
                if (Tasting) {
                    this.totaleVisita = Number(Nperson) * this.prezzi.PrezzoAdultoDeg + Number(Nchild) * this.prezzi.PrezzoBambinoDeg;
                }
                
                if (Tasting2) {
                    this.totaleVisita = Number(Nperson) * this.prezzi.PrezzoAdultoAnolini + Number(Nchild) * this.prezzi.PrezzoBambinoAnolini;
                }

                if (VisitTime == '') {
                    this.totaleVisita = 0;
                } else {
                    const VisitDate = this.visitForm.get('VisitDate')?.value;
                    if ((VisitDate == '2024-10-05' || VisitDate == '2024-10-06') && VisitLang == 'italiano') {
                        this.totaleVisita = Number(Nperson) * this.prezzi.PrezzoAdulto;
                        if (Tasting) {
                            this.totaleVisita = Number(Nperson) * this.prezzi.PrezzoAdultoDeg;
                        }
                        if (Tasting2) {
                            this.totaleVisita = Number(Nperson) * this.prezzi.PrezzoAdultoAnolini;
                        }
                    }
                }
                
                resolve(); // Completa la Promise una volta calcolato il totale
            }, (error) => reject(error));
        } else {
            this.totaleVisita = 0;
            resolve(); // Completa la Promise se non ci sono prezzi da recuperare
        }
    });
}


  calcolaTotaleVisita(nrPerson: number, nrChilds: number, data: string, degustazione?: boolean, lingua?: string, degustazione2?: boolean) {
    var calcolaTot = 0;

    calcolaTot = nrPerson * this.prezzi.PrezzoAdulto + nrChilds * this.prezzi.PrezzoBambino;
    if (degustazione && degustazione === true) {
        calcolaTot = nrPerson * this.prezzi.PrezzoAdultoDeg + nrChilds * this.prezzi.PrezzoBambinoDeg;
    }
    if (degustazione2 && degustazione2 === true) {
        calcolaTot = nrPerson * this.prezzi.PrezzoAdultoAnolini + nrChilds * this.prezzi.PrezzoBambinoAnolini;
    }
    

    if ((data == '2024-10-05' || data == '2024-10-06') && lingua == 'italiano') {
      calcolaTot = nrPerson * 7;
      if (degustazione == true) {
        calcolaTot = nrPerson * 10;
      }
      if (degustazione2 == true) {
        calcolaTot = nrPerson * 15;
      }
    }
    return calcolaTot;
  }

  openNewTab() {
    window.open('/privacy', '_blank');
  }

  private setSlides() {
    this.slidersService.getVisitSlides().subscribe(res => {
      this.sliders = res;
    })
  }
  
  async changeVisitDate() {
    const Nperson = this.visitForm.get('Nperson')?.value;
    const Nchild = this.visitForm.get('Nchild')?.value;
    const VisitDate = this.visitForm.get('VisitDate')?.value;
    const VisitLang = this.visitForm.get('Language')?.value;
    const tot = Number(Nperson) + Number(Nchild);

    if (tot && tot !== 0) {
        const response = await this.httpVisit.setVisitTimes(VisitDate, VisitLang, tot).toPromise();
        
        if (response && response.length > 0) {
            this.timeOptions = response;
            this.visitForm.get('VisitTime').setValue(response[0].id); // Imposta il primo orario disponibile

            // Carica i prezzi per il primo orario e calcola il totale
            await this.calcolaTotale();
        } else {
            this.timeOptions = [
                {
                    id: "",
                    ora: "Non ci sono orari disponibili, si consiglia di scegliere un altro giorno o lingua."
                }
            ];
            this.visitForm.get('VisitTime').setValue('');
            this.totaleVisita = 0;
        }
    } else {
        this.timeOptions = [
            {
                id: "",
                ora: "Prima selezionare il N° persone, la data e la lingua"
            }
        ];
        this.visitForm.get('VisitTime').setValue('');
        this.totaleVisita = 0;
    }
  }


  changeVisitTime(evento: any) {
    this.visitForm.get('VisitTime').setValue(evento.target.value);
    console.log("visit time:", this.visitForm.get('VisitTime')?.value)

  }
}
